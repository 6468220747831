.fade-image {
    transition: opacity 1s ease-in-out;
    opacity: 1;
  }
  
  .fade-in {
    opacity: 1;
  }
  
  .fade-out {
    opacity: 0;
  }
  