.container {
  align-content: center;
  float: inherit;
  margin-inline-start: auto;
  
  justify-items: center;  
  justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust as needed */
  }
  
  .centered {
    text-align: center;
  }
  .container2 {
    justify-content: center;
    align-items: center;
    height:"100%";
   }